<template>
  <div>
    <b-img :src="logo" fluid alt="lgbbet"></b-img>
  </div>
</template>

<script>
import { BImg } from 'bootstrap-vue'

export default {
  components: {
    BImg
  },
  data() {
    return {
      logo: 'betded-logo.png'
    }
  },
  async beforeMount() {
    console.log(window.location.hostname)

    const { hostname } = new URL(window.location.href)
    console.log(hostname.split('.')[0])
    if (hostname.split('.')[0] === 'mahaheng') {
      this.logo = require('../../assets/images/logo/mahaheng-logo.png')
    } else {
      this.logo = require('../../assets/images/logo/betded-logo.png')
    }
  }
}
</script>
